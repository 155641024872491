<template>
  <v-navigation-drawer
    v-model="inputValue"
    fixed
    right
    temporary
  >
    <v-row
      align="center"
      class="ma-0 pa-3 flex-no-wrap"
    >
      <v-img
        contain
        src="/logo.png"
        width="80"
      />
      <v-btn
        aria-label="Close"
        class="ml-4"
        icon
        @click="toggleDrawer"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-divider />
    <v-list>
      <v-list-item
        v-for="(item, i) in items"
        :key="i"
        :to="item.to"
      >
        <v-list-item-title v-text="item.text" />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  import { mapState, mapMutations } from 'vuex'
  export default {
    name: 'HomeDrawer',
    data: () => ({
      items: [
          {
            'to': '/',
            'text': 'Inicio'
          },
          {
            'to': '/about',
            'text': 'Nosotros'
          },
          {
            'to': '/event',
            'text': 'Eventos'
          },
          {
            'to': '/contact',
            'text': 'Contacto'
          },
          {
            'to': '/certificate',
            'text': 'Certificados'
          }
      ]
    }),
    computed: {
      ...mapState(['drawer']),
      inputValue: {
        get () {
          return this.drawer
        },
        set (val) {
          this.setDrawer(val)
        }
      }
    },
    methods: {
      ...mapMutations(['setDrawer', 'toggleDrawer'])
    }
  }
</script>
